<template>
  <div class="not-found">
    <loading-screen v-if="loading404"/>
    <div v-else class="content">
      <img class="not-found-circles" src="@/assets/global-icons/not-found-circles.svg" alt="not found" />
      <div class="text">
        <h1>Page Not Found</h1>
        <span>You will be automatically redirected soon</span>
      </div>
      <btn large colour="gold" @onClick="goToHome" >
        <template #text ><span :style="{padding: '0.5rem'}">Back to SPACEIN</span></template>
      </btn>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LoadingScreen from '@/components/global/loading/LoadingScreen.vue';

export default {
  name: "App",
  components: { LoadingScreen },
  data() {
    return {
      timeout: null,
    };
  },
  computed: {
    ...mapGetters({
      loading404: 'loading/isLoading404'
    }),
  },
  watch: {
    loading404() {
      this.initReRouteTimeout()
    }
  },
  methods: {
    ...mapActions('routing', ['goToHome']),
    initReRouteTimeout() {
      if(!this.loading404) {
        this.timeout = setTimeout(() => {
          this.goToHome()
        }, 3000)
      }
    }
  },
  mounted() {
    this.initReRouteTimeout()
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  }
};
</script>

<style scoped lang="scss">
.not-found {
  height: calc(100% + 5rem); //sidebar padding on app-container-content
  width: 100%;
  display: grid;
  background: $grey-7;
  color: grey;
}
.content {
  display: grid;
  align-content: center;
  justify-items: center;
  text-align: center;
  grid-gap: 3rem;
  padding: 1rem;

  .not-found-circles {
    width: 120px;
  }

  .text {
    display: grid;
    gap: 0.8rem;

    h1 {
      font-size: 280%;
    }
  }
  button {
    margin-top: 2rem;
  }
}
</style>
